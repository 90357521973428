body{
    font-size: 12px;
    padding-top: 50px;
    line-height: 18px;
    color: #333;
    background: #f9f9f9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
}

#app-player{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    svg{
        width: 100%!important;
        height: 100%!important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        [id*="Hybrid"]{
            position: absolute!important;
        }
    }
    .app-player-program{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        // transform: translateX(-100%);
        &.active {
            z-index: 1;
            // transition: 0.3s all ease-in-out;
            // transform: translateX(0);
        }
        &.slideIn{
            transform: translateX(0);
        }
        &.slideOut{
            transform: translateX(100%);
        }
        >div:nth-child(2){
            top: 0;
            left: 0;
            // right: 0;
            // bottom: 0;
            position: fixed;
            display: flex;
            width: 100%;
            height: 100%;
        }
    }
}
.form-setting{
    margin: auto;
    max-width: 460px;
    min-width: 360px;
}

.modal-config-player{
    top: 100px;
}