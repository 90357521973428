// [data-topbar=dark] #page-topbar {
//     background-color: var(--vz-header-bg-dark);
//     border-color: var(--vz-header-bg-dark);
// }

.loading-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--vz-header-bg-dark);
    z-index: 9999;
    &::after{
        content: "";
        width: 60px;
        height: 60px;
        position: absolute;
        top: -30px;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        border: 6px solid #f2f2f2;
        border-top: 6px dotted #f2f2f2;
        border-bottom: 6px dotted #f2f2f2;
        border-radius: 50%;
        animation: loading 2s infinite;
    }
    &::before{
        font-family: 'Lobster', cursive;
        font-size: 20px;
        letter-spacing: 1px;
        color: #000;
        content: "Loading...";
        position: absolute;
        top: 57%;
        text-align: center;
        right: 0;
        left: 0;
        margin: auto;
    }
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(360deg);
    }
}