.span2.layout_image {
  display        : block;
  text-align-last: center;
  text-align     : -webkit-center;
  margin-bottom  : 20px;
}

.span6.layout_image {
  display        : block;
  text-align-last: center;
  text-align     : -webkit-center;
  margin-bottom  : 20px;
}

.span3.layout_image {
  display        : block;
  text-align-last: center;
  text-align     : -webkit-center;
  margin-bottom  : 20px;
}

.layout_image .resource_title-image {
  margin       : 0 5px;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.box_content-edit {
  width: 350px;
}

.tbl-tools-searchbox2.rootGroup {
  height         : 26px;
  text-align     : center;
  text-align-last: center
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.company_user-1 {
  display        : block;
  font-weight    : 700;
  width          : 150px;
  padding        : 1px 5px;
  text-align     : center;
  margin-top     : 2px;
  color          : #36a400;
  border         : 1px solid #52c41a;
  text-align-last: center;
  border-radius  : 5px;
  background     : #36a40021;
}

.company_user-2 {
  display         : block;
  font-weight     : 700;
  width           : 150px;
  padding         : 1px 5px;
  text-align      : center;
  margin-top      : 2px;
  color           : #ff4d4f;
  border          : 1px solid #ff4d4f;
  text-align-last : center;
  border-radius   : 5px;
  background-color: #ff000012;
}

.company_status-1 {
  display    : block;
  font-weight: 700;
  color      : #1677ff;
}

.company_status-2 {
  display    : block;
  font-weight: 700;
  text-align : center;
  color      : #ff4d4f;
}

.dropdown-menu .time:hover {
  background: '#91caff'
}

.layoutCanvas {
  width        : 100%;
  display      : flex;
  place-content: center;
  align-items  : center;
  position     : relative
}

.layoutCanvas .layoutCanvas_p1 {
  position  : absolute;
  height    : 1px;
  content   : '12';
  background: black;
  margin    : 0px;
  top       : -10px;

  .layoutCanvas_p1-span {
    position     : absolute;
    bottom       : -8px;
    left         : 0;
    right        : 0;
    margin       : 0 auto;
    display      : flex;
    place-content: center;
    font-size    : 16px;
    color        : black;
    font-weight  : 500;
    background   : #fff;
    z-index      : 10;
    width        : 80px;
  }

  &::after {
    position     : absolute;
    content      : '';
    bottom       : -5px;
    right        : 0;
    width        : 0;
    height       : 0;
    border-top   : 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left  : 10px solid black;
    z-index      : 20;
    background   : #fff;
  }

  &::before {
    position  : absolute;
    content   : '';
    top       : -5px;
    left      : 0;
    width     : 2px;
    height    : 10px;
    background: black;

  }
}

.layoutCanvas .layoutCanvas_p {
  position  : absolute;
  height    : 100%;
  width     : 1px;
  content   : '12';
  background: black;
  margin    : 0px;

  .layoutCanvas_p1-span2 {
    position     : absolute;
    bottom       : 0px;
    top          : 0px;
    left         : -43px;
    margin       : auto;
    display      : flex;
    place-content: center;
    font-size    : 16px;
    color        : black;
    font-weight  : 500;
    background   : #fff;
    z-index      : 10;
    /* height    : 20px; */
    width        : 80px;
    transform    : rotate(270deg);
    align-items  : center;
    height       : 20px;
  }

  &::after {
    position     : absolute;
    content      : '';
    bottom       : 0px;
    right        : -5px;
    width        : 0;
    height       : 0;
    border-top   : 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left  : 10px solid black;
    z-index      : 20;
    transform    : rotate(90deg);
    z-index      : 20;
    background   : #fff;
  }

  &::before {
    position  : absolute;
    content   : '';
    top       : -5px;
    left      : 0;
    width     : 2px;
    height    : 10px;
    background: black;
    transform : rotate(90deg);
  }
}

.btn i {
  margin      : 0px;
  margin-right: 2px;
}

// auth
.auth {
  .auth_detail {
    display              : inline-block;
    padding              : 4px;
    font-size            : 13px;
    line-height          : 18px;
    color                : #333333;
    /* border            : 1px solid #ccc; */
    -webkit-border-radius: 3px;
    -moz-border-radius   : 3px;
    border-radius        : 3px;
    /* font-size         : 18px; */
    font-weight          : 500;
    width                : 100%;
    padding              : 0;
    display              : grid;
    justify-items        : center;

    .auth_detail-label {
      padding  : 5px 10px;
      max-width: 100%;
      display  : flex;

      label {
        width        : 120px;
        margin-right : 10px;
        margin-bottom: 0px;
        align-self   : center;
      }

      .label_input {
        width        : 300px;
        margin-bottom: 0px;
      }
    }

    .auth_detail-label.controls {
      padding: 40px 10px 5px 10px;
    }

    .auth_detail-label.bottoms {
      padding: 5px 10px 40px 10px;
    }
  }
  .btn_save-auth {
    width           : 100px;
    margin-left     : 340px;
    // margin-bottom: 50px;
  }
}

// div
.div_divider{
  width: 100%;
  height: 3px;
  background-color: #eeeeee;
  border-bottom: 1px solid #ffffff;
}
.disabled_click{
  padding: 3px 10px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #999999;
    white-space: nowrap;
}
.icon_close-password{
  position: absolute;
  top: 20%;
  left: 5px;
}
.p_error{
  color: red
}